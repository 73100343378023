.Container{
    display: flex;
    min-height: 960px;
    background-color: #A38067;
    flex-direction: column;
}
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 15px;
}
.navEl{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navEl a{
    font-size: 17px;
    color: #E9D3BD;
    padding-bottom: 5px;
    text-underline-position: under;
    margin: 5px;
  
}

.navEl svg{
    color: #E9D3BD;
}

.navEl a:hover{
    text-decoration: wheat;
}


.contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Form{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 300px;

}