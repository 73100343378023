
.newsbar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
}

select {
    
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0!important;
    background: #E9D3BD;
    background-image: none;
    flex: 1;

    color:#000;
    cursor:pointer;
    font-size: 15px;
    font-family: 'Almarai';
    text-align: center;
 }
 select::-ms-expand {
    display: none;
 }
 .select {
    margin-top: 10px;
    width: 190px;
    position: relative;
    display: flex;
    width: 14em;
    height: 2.8em;
    line-height: 3;
    background: #5c6664;
    overflow: hidden;
    border-radius: .25em;
    margin-bottom: 10px;
 }
 .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0.7em;
    color: #7C6252;
    cursor:pointer;
    pointer-events:none;
    transition:.25s all ease;
    background: #E9D3BD;
 }
 .select:hover::after {
    color: #a1755a;
 }

.note{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: tomato;
    width: 90px;
    color: white;
}

.tcontainer {
    width: 90%;
    overflow: hidden; 
  }
   
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    padding-left: 100%; 
    background-color: #eee;
  }
  
  @keyframes ticker {
    0% { transform: translateX(-90%); }
    100% { transform: translateX(0); }
  }
  .move {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 25s;
  }
  .move:hover{
    animation-play-state: paused; 
  }
  
  .item{
    flex: 1;
    display: inline-block;
    padding: 0 2rem;
    font-size: 14px;
  }

.Container{
    display: flex;
    height: 960px;
    background-color: #A38067;
    flex-direction: column;
}

.Container h1{
    margin-top: 60px;
    text-align: center;

}
.Form{
    align-self: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 500px;
    background-color: #d1c6c0;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
    box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
    border-radius: 20px;
}

.Form label{
    justify-self: flex-start;
    margin: 6px;
    text-align: end;
   
}

.iconColor{
    color: #7C6252;
}
.date{
    border: 0;
}

.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 15px;
    height: 30px;
}
.navEl{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navEl a{
    font-size: 17px;
    color: #E9D3BD;
    padding-bottom: 5px;
    text-underline-position: under;
    margin: 5px;
  
}

.navEl svg{
    color: #E9D3BD;
}

.navEl a:hover{
    text-decoration: wheat;
}

.radio{
    width: 70%;
    align-items: flex-end;
}

.radio span,
.radio label{
    font-family: 'Almarai';
    font-size: 15px;
}

@media screen and (min-width: 600px){
    .Form{
        width: 390px;
    }
}