.Container{
    display: flex;
}

.One{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #7C6252;
    width: 45%;
    height: 670px;
    padding: 20px;
}

.Two{
    display: flex;
    align-items: center;    
    flex-direction: column;
    background-color: #C5B7B0;
    width: 55%;
    height: 670px;
    padding: 20px;
}

.OneHead{
    margin: 60px 0px 0px 0px;
    font-size: 36px;
    color: #E9D3BD;
 }

.CenterText{
   font-size: 19px;
   text-align: right;
   color: #E9D3BD;
   margin: auto;

}

.TwoHead{
    color: #7C6252;
    margin: 60px 0px 0px 0px;
    font-size: 36px;
}

.Contact{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: auto;
    width: 80%;
}
.myInput{
    width: 350px;
    height: 120px;
}

@media screen and (max-width:890px){
     .Container{
         flex-direction: column;
     }
     .One{
         padding: 30px;
         width: 100%;
     }
     .Two{
         width: 100%;
     }
     .CenterText{
         font-size: 18px;
         padding: 34px;
     }
     .OneHead,
     .TwoHead{
         font-size: 30px;
     }
     .One,
     .Two{
         padding: 0px;
     }

}