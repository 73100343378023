.Container{
    display: flex;
    height: 960px;
    background-color: #A38067;
    flex-direction: column;
}


.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 15px;
}
.navEl{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navEl a{
    font-size: 17px;
    color: #E9D3BD;
    padding-bottom: 5px;
    text-underline-position: under;
    margin: 5px;
  
}

.navEl svg{
    color: #E9D3BD;
}

.navEl a:hover{
    text-decoration: wheat;
}


.contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.user{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E9D3BD;
    margin-bottom: 5px;
}

.userIcon{
    flex: 1;
    font-size: 40px;
    color: #7C6252;
}

.form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 290px;
    height: 190px;
    background-color: #C7B09F;
    border-radius: 20px;
    padding: 30px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
    box-shadow: 10px 10px 5px 0px rgba(107,87,55,1);
}
