.Footer{
    display: flex;
    flex-direction: column;
    background-color: #C5B7B0;
    justify-content: center;
    align-items: center;
    height: 15vh;
    padding: 15px 0px 0px 0px;
}

.Contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Contents p{
    font-size: 12px;
}

.Contents a{
    margin: 5px;
    padding: 2px;
    font-size: 16px;
    color: #7C6252;
    text-underline-position: under;
}

.Contents a:hover{
    text-decoration: none;
}

.Circle{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #7C6252;
    transition: background-color 0.2s ease;
    margin: 5px;
}

.Circle:hover{
    background-color: #a1755a;
}

.Circle a{
    margin: 0;
    padding: 0;
    height: 24px;
}

.Icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.icon{
    color: #E9D3BD;
}



@media screen and (max-width:1366px){
    .Circle{
        width: 40px;
        height: 40px;
        margin: 5px;
    }
    .Contents a{
        margin: 1px;
        padding: 2px;
        font-size: 16px;
        color: #7C6252;
        text-underline-position: under;
    }
}