.SectionOne{
    display: flex;
    height: 60vh;
}

.BackgroundImage{
    background-image: linear-gradient(270.37deg, #FFFFFF 0.3%, rgba(255, 255, 255, 0) 53.22%), 
    url('../../../../assets/Home.jpg');
    background-position: center;
    background-size: cover;
    height: 680px;
    height: 60vh;
    width: 55%;
}

.SiteInfo{
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    width: 45%;
    margin-top: 30px;
}

.SiteName{
    font-size: 65px;
    font-weight: 700;
    color: #656F7C;
    margin: 30px 0px 5px 0px;
    padding: 15px 0px 0px 0px;
}

.SmallText{
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #656F7C;
}

.InfoText{
    width: 370px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    margin: 40px 0px;
}

.Btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px;
}


/* more than 1600 */
@media screen and (min-width:1600px){
    .SectionOne{
        display: flex;
        height: 60vh;
    }
    .BackgroundImage{
        height: 60vh;
    }
    .SiteInfo{
        margin-top: 30px;
    }
}

/* less than 1600 */
@media screen and (max-width:1600px){
    .SectionOne{
        display: flex;
        height: 80vh;
    }
    .BackgroundImage{
        height: 80vh;
    }
    .SiteInfo{
        margin-top: 20px;
    }
    .SiteName{
        font-size: 55px;
    }
}


/* less than 1280 */
@media screen and (max-width:1280px){
    .SectionOne{
        display: flex;
        height: 80vh;
    }
    .BackgroundImage{
        height: 80vh;
    }
}



/* less than 890 */
@media screen and (max-width:890px){
    .SectionOne{
        display: flex;
        height: 60vh;
    }
    .Darker{
        position: absolute;
        top: 0;
        left: 0;
        height: 60vh;
        width: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1;
    }
    .BackgroundImage{
        background-image: url('../../../../assets/Home.jpg');
        width: 100%;
        height: 60vh;
    }
    .SiteInfo{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 10;
        left: 0;
        margin: auto;
        width: 100%;
    }
    .SiteName{
        color: #E9D3BD;
    }
    .SmallText{
        color: #E9D3BD;
    }
    .InfoText{
        color: white;
        font-size: 16px;
        width: 340px;
        margin: auto;
        margin-top: 15px;
    }
    
    .Btns{
        flex-direction: column;
    }
    .Btn{
        margin: 15px;
        width: 200px;
    }
}

/* less than 375 */
@media screen and (max-width:375px){
    .SectionOne{
        height: 67vh;
    }
    .Darker{
        height: 67vh;
    }
    .BackgroundImage{
        height: 67vh;
    }
    .SiteInfo{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 10;
        left: 0;
        margin: auto;
        width: 100%;
    }
    .SiteName{
        color: #E9D3BD;
        font-size: 50px;
    }
    .InfoText{
        font-size: 16px;
        width: 340px;
        margin: auto;
    }
}